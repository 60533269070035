<template>
  <el-dialog
    :title="title"
    :visible.sync="show"
    destroy-on-close
    :width="width"
    top="30vh"
    center
    @close="onClose"
  >
    <div class="content">
      <span>{{ message }}</span>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button :type="cancelType" @click="_cancel">{{ cancelText }}</el-button>
      <el-button :type="confirmType" @click="_confirm">{{ confirmText }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
/* eslint-disable vue/require-default-prop */
export default {
  name: "ProgressPrompt",
  props: {
    width: {
      type: String,
      default: "38%",
    },
    visible: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    /**
     *
     */
    cancel: {
      type: Function,
      required: false,
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    cancelType: {
      type: String,
      default: "plain",
    },
    /**
     *
     */
    confirm: {
      type: Function,
      required: false,
    },
    confirmText: {
      type: String,
      default: "Ok",
    },
    confirmType: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
      show: this.visible,
    };
  },
  watch: {
    visible: function (newVal) {
      this.show = newVal;
    },
  },
  methods: {
    _cancel() {
      if (typeof this.cancel === "function") {
        this.cancel();
      }
      this._close();
    },
    _confirm() {
      if (typeof this.confirm === "function") {
        this.confirm();
      }
      this._close();
    },
    _close() {
      this.show = false;
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  text-align: center;
}
</style>
