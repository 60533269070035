/* eslint-disable no-unused-vars */
/**
 * @typedef {Object} PromptOptions options for display prompts
 * @property {String} confirm Display text for the confirm option
 * @property {String} cancel  Display test for the cancel option
 * @property {String} confirmClass
 * @property {String} cancelClass
 * @property {Function} confirmCallback
 * @property {Function} cancelCallback
 */

/**
 * @typedef {Object} filterOption
 * @property {String} name
 * @property {String} value
 */

import ProgressPrompt from "../../components/ProgressPrompt.vue";
import { mapGetters } from "vuex";
import { UPLOAD_STAGE } from "../../store/stages";

export default {
  components: {
    ProgressPrompt,
  },
  data() {
    return {
      progress: 0,

      actionName: "Upload Data",
      isApplyingChecks: false,
      isProcessing: false,

      filtersFrom: "editting",

      // Table properties
      sortableFields: [
        { name: "indicator", label: "Select Indicator" },
        { name: "value", label: "Select value", filter: false },
        { name: "value_type", label: "Select Type" },
        { name: "period", label: "Select Year" },
        { name: "location", label: "Select Location", filter: false },
      ],
      tableFilters: {},
      perPageSize: 25,
      tableLoading: false,
      selectedRows: [],

      // Prompt properties
      isProgresPromptVisible: false,

      promptTitle: "",
      promptMessage: "",
      promptWidth: "38%",

      promptCancelClass: "",
      promptCancelText: "",

      promptConfirmClass: "",
      promptConfirmText: "",
      /**
       * @type {(Function|undefined)}
       * Cancel handler for progress prompt
       */
      _promptCancel: null,
      /**
       * @type {(Function|undefined)}
       * confrim handler for progress prompt
       */
      _promptConfirm: null,
    };
  },
  created: function () {
    if (
      this.$route.name !== "DataUpload" &&
      this.$route.name !== "DataPreview" &&
      this.$route.name !== "DataRecords" &&
      this.stage === UPLOAD_STAGE
    ) {
      this.$router.replace("/data/upload");
    }
  },
  computed: {
    /**
     * Get data state from store
     */
    ...mapGetters({
      editting: "data/editting",
      headers: "data/headers",
      currentRecord: "data/record",
      stage: "data/stage",
      percentage: "progress",
    }),
    // Common properties
    filteredData() {
      const filters = Object.entries(this.tableFilters).filter((filter) => filter[1]);
      const source = this.filtersFrom === "editting" ? this.editting : this.preview;
      if (!source.length) return source;
      return source.filter((x) => {
        for (let i = 0; i < filters.length; i++) {
          let q = filters[i];
          if (x[q[0]] !== q[1]) return false;
        }
        return true;
      });
    },
    promptCancel() {
      if (this.promptMessage.length && this.promptTitle.length && this._promptCancel)
        return this._promptCancel;
      return null;
    },
    promptConfirm() {
      if (this.promptMessage.length && this.promptTitle.length && this._promptConfirm)
        return this._promptConfirm;
      return null;
    },
    total() {
      return this.filteredData.length;
    },
    fields() {
      const fields = [];
      const sortableFields = this.sortableFields.map((x) => x.name);
      for (let i = 0; i < this.headers.length; i++) {
        let header = this.headers[i];
        let f = { key: header, sortable: false };
        if (sortableFields.indexOf(header) !== -1) {
          f.sortable = true;
        }
        fields.push(f);
      }
      return fields;
    },
    isFiltered() {
      return Object.entries(this.tableFilters).filter((filter) => filter[1]).length > 0;
    },
    tableDescription() {
      const source = this.isFiltered
        ? this.filteredData
        : this.filtersFrom === "editting"
        ? this.editting
        : this.preview;
      const amount = this.perPageSize > source.length ? source.length : this.perPageSize;
      return `Showing ${amount} of ${source.length} records`;
    },
    // Place holder for no upload componenets like saved data and data records
    preview() {
      return [];
    },
    filters() {
      const source = this.filtersFrom === "editting" ? this.editting : this.preview;
      return this.sortableFields
        .filter((x) => x.filter !== false)
        .map((field) => {
          let options = source.map((x) => {
            return x[field.name];
          });
          options = Array.from(new Set(options));
          return {
            ...field,
            options,
          };
        });
    },
    edittingEnabled() {
      return this.editting.length != 0;
    },
    emptyText() {
      if (this.editting.length < 1) return "No Data to render";
      if (this.editting.length > 0 && this.filteredData.length < 1)
        return "No data  Matching current filter selections";
    },
    showLoader() {
      return this.isApplyingChecks || this.isProcessing;
    },
  },
  methods: {
    /**
     *
     * @param {*} rows
     */
    handleSelection(rows) {
      this.selectedRows = rows;
    },

    /**
     *
     */
    handleDeleteSelectedCells() {
      const cells = this.selectedRows.map((x) => x.$id);
      if (!cells.length) return;

      this.showProgressPrompt(
        "Delete Entries",
        `Are you sure you want to delete selected rows (${cells.length}) ?`,
        {
          cancel: "Cancel",
          confirm: "Yes Delete",
          confirmClass: "danger",
          async confirmCallback() {
            try {
              this.$progress.start();
              this.tableLoading = true;
              await this.$store.dispatch("data/deleteCells", cells);

              // Revert all validations
              await this.revertToEdit();

              this.onRowsDeleted(cells.length);
              this.$progress.done();
              this.tableLoading = false;
              this.$notify.success(`${cells.length} Rows deleted`);
            } catch (error) {
              this.$notify.error("Error deleting rows");
              this.$progress.done();
              this.tableLoading = false;
            }
          },
        }
      );
    },

    /**
     *
     * @param {Number} count Number of deleted rows
     */
    onRowsDeleted(count) {},

    /**
     * Add a filter option to the filter Object
     * @see {tableFilters}
     * @param {filterOption} entry
     * @param {Event} event
     */
    setFilter(entry, event) {
      this.$set(this.tableFilters, entry.name, event.target.value);
    },

    /**
     *
     * @param {Number} size
     */
    handlePageSizeChange(size) {
      this.perPageSize = size;
    },

    /**
     *
     * @param {*} value
     */
    async setRequestProgress(value) {
      this.progress = value;
    },
    /**
     * @param {String} title
     * @param {String} message
     * @param {PromptOptions} param2
     */
    showProgressPrompt(
      title,
      message,
      {
        confirm = "Confirm",
        cancel = "Cancel",
        cancelClass = "plain",
        confirmClass = "primary",
        confirmCallback = function () {},
        cancelCallback = function () {},
      } = {}
    ) {
      this._promptConfirm = confirmCallback.bind(this);
      this._promptCancel = cancelCallback.bind(this);

      this.promptCancelText = cancel;
      this.promptCancelClass = cancelClass;

      this.promptConfirmText = confirm;
      this.promptConfirmClass = confirmClass;

      this.promptMessage = message;
      this.promptTitle = title;

      this.isProgresPromptVisible = true;
    },
    /**
     *
     */

    onPromptClose() {
      this.isProgresPromptVisible = false;
      this._processConfirm = null;
      this._progressCancel = null;

      this.promptCancelText = "";
      this.promptCancelClass = "";

      this.promptConfirmText = "";
      this.promptConfirmClass = "";

      this.promptMessage = "";
      this.promptTitle = "";
    },
    /**
     * cancels the current state
     */
    cancel() {
      this.showProgressPrompt("Cancel progress", "Are you sure you want to cancel your progress?", {
        cancel: "Continue",
        confirm: "Cancel progress",
        confirmClass: "danger",
        async confirmCallback() {
          await this.$store.dispatch("data/cancelProgress");
          this.$router
            .push("/data/upload")
            .then(() => {})
            .catch((e) => console.log(e));
        },
      });
    },
    /**
     *
     */
    async revertToEdit() {
      // Reset all validations
      await this.$store.dispatch("data/reset_upload_state");
      this.$router
        .push("/data/preview")
        .then(() => {})
        .catch((e) => console.log(e));
    },
    /**
     *
     * @param {*} el
     * @param {*} text
     * @param {*} background
     * @returns
     */
    createLoader(el = null, text = null, background = null) {
      const config = {
        target: el || this.$el,
        lock: true,
        text: text || "Processing",
        spinner: "el-icon-loading",
        background: background || "rgba(255, 255, 255, 0.9",
      };
      return this.$loading(config);
    },
  },
};
